import { usePassThrough } from 'primereact/passthrough';
import Tailwind from 'primereact/passthrough/tailwind';
import { classNames } from 'primereact/utils';
import { TabMenuContext, TabMenuState } from 'primereact/tabmenu';

export default function usePrPassthrough() {
	return usePassThrough(
		Tailwind,
		{
			button: {
				label: {
					className: 'font-medium',
				},
				root: {
					className: 'focus:shadow-none',
				},
			},
			dialog: {
				header: { className: '!bg-gardian-darkblue text-white' },
				content: {
					className: '!bg-gardian-lightgray pt-8 flex flex-col gap-6',
				},
				footer: {
					className: '!bg-gardian-lightgray !px-4 !pb-2',
				},
				closeButton: {
					className:
						'text-white hover:bg-transparent hover:text-white',
				},
			},
			fieldset: {
				legend: {
					className: 'focus:shadow-none',
				},
			},
			datatable: {
				paginator: {
					RPPDropdown: {
						root: {
							className: '!w-20',
						},
					},
				},
				header: {
					className: '!p-0',
				},
			},
			inputtext: {
				root: {
					className: 'font-medium focus:shadow-none',
				},
			},
			tabmenu: {
				root: {
					className: 'p-0 overflow-hidden',
				},
				action: ({
					context,
					state,
				}: {
					context: TabMenuContext;
					state: TabMenuState;
				}) => ({
					className: classNames(
						'!py-2 !px-4 !top-0  !rounded-none !shadow-none !bg-transparent',
						'!border-0 p-5 font-bold rounded-t-lg ',
						{
							'hover:bg-white hover:!border-gray-400 hover:text-gray-600 dark:bg-gray-900 dark:border-blue-900/40 dark:text-white/80 dark:hover:bg-gray-800/80':
								state.activeIndex !== context.index, // Condition-based hover styles.
							'!border-b-2 bg-white border-blue-500 text-blue-500 dark:bg-gray-900 dark:border-blue-300 dark:text-blue-300':
								state.activeIndex === context.index, // Condition-based active styles.
						}
					),
					style: { top: '2px' },
				}),
				inkbar: {
					className: 'hidden',
				},
				label: {
					className: 'font-normal',
				},
				menu: {
					className: '!bg-transparent',
				},
			},
		},
		{
			mergeSections: true,
			mergeProps: true,
		}
	);
}
