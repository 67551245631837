import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import cgiar from '../../assets/CGIAR_footer.png';
import { footerContent } from './assets/footer-content';

type FooterDialogProps = {
	type: string;
	dialogStatus: boolean;
	setDialogStatus: React.Dispatch<boolean>;
};

const FooterDialog = ({
	type,
	dialogStatus,
	setDialogStatus,
}: FooterDialogProps) => {
	const getDialogContent = (dialogType: string) => {
		switch (dialogType) {
			case 'PRIVACY NOTICE':
				return footerContent.privacy;
			case 'DISCLAIMER NOTICE':
				return footerContent.disclaimer;
			case 'TERMS OF USE':
				return footerContent.terms;
			default:
				return null;
		}
	};

	return (
		<Dialog
			header={type}
			visible={dialogStatus}
			onHide={() => {
				if (!dialogStatus) return;
				setDialogStatus(false);
			}}
			maximizable
			pt={{
				root: {
					className: 'w-[80vw] max-h-[80vh]',
				},
				content: {
					className: 'h-full',
				},
			}}
			draggable={false}
			blockScroll
		>
			{footerContent.common}
			{getDialogContent(type)}
		</Dialog>
	);
};

export const Footer = () => {
	const [dialogStatus, setDialogStatus] = useState<boolean>(false);
	const [dialogType, setDialogType] = useState<string>('privacy');

	const toggleDialog = (type: string) => {
		setDialogType(type);
		setDialogStatus(true);
	};

	return (
		<div className="flex flex-col bg-gardian-darkblue">
			<div className="mx-20">
				<div className="flex flex-row items-center justify-between p-3">
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
					<Link
						className="mt-auto"
						to="https://creativecommons.org/licenses/by-nc-sa/4.0/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<div className="flex flex-row gap-3">
							<i className="fab fa-creative-commons text-2xl text-white" />
							<i className="fab fa-creative-commons-by text-2xl text-white" />
							<i className="fab fa-creative-commons-nc text-2xl text-white" />
							<i className="fab fa-creative-commons-sa text-2xl text-white" />
						</div>
					</Link>
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
					<a
						href="https://www.cgiar.org/"
						target="_blank"
						rel="noreferrer"
						style={{ color: '#a5a5a5' }}
					>
						<img src={cgiar} alt="" style={{ height: '66px' }} />
					</a>
				</div>
				<div className="-mt-[8-px] h-px w-full bg-white" />
				<div className="flex flex-row items-center justify-between p-3">
					<div className="flex flex-row items-center justify-between gap-2">
						<FooterDialog
							type={dialogType}
							dialogStatus={dialogStatus}
							setDialogStatus={setDialogStatus}
						/>
						<Button
							text
							label="Privacy"
							pt={{
								root: {
									className: '!py-1 !px-2',
								},
								label: {
									className: 'text-white',
								},
							}}
							onClick={() => toggleDialog('PRIVACY NOTICE')}
						/>
						<div className="h-6 w-px bg-white" />
						<Button
							text
							label="Disclaimer"
							pt={{
								root: {
									className: '!py-1 !px-2',
								},
								label: {
									className: 'text-white',
								},
							}}
							onClick={() => toggleDialog('DISCLAIMER NOTICE')}
						/>
						<div className="h-6 w-px bg-white" />
						<Button
							text
							label="Terms of Use"
							pt={{
								root: {
									className: '!py-1 !px-2',
								},
								label: {
									className: 'text-white',
								},
							}}
							onClick={() => toggleDialog('TERMS OF USE')}
						/>
					</div>
					<div className="">
						<a
							href="https://scio.systems/"
							target="_blank"
							rel="noreferrer"
							style={{ color: '#a5a5a5' }}
						>
							<span>powered by</span>
							<span
								style={{ fontSize: 'larger', color: '#dcdcdc' }}
							>
								{' '}
								SCiO
							</span>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};
